import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { environment } from 'environment/environment'
import { HubtypeCase } from 'models/hubtype-case'
import { HubtypeChatSummary } from 'models/hubtype-chat-summary'
import { HubtypeMessage } from 'models/hubtype-message'
import { HubtypeMessageTranslation } from 'models/hubtype-translation-language'
import { Observable, catchError, map, tap, throwError } from 'rxjs'
import { Analytics } from 'utils/analytics'
import * as deskAction from '../actions/desk'
import { AnalyticsEvents } from '../constants/analytics'
import * as fromRoot from '../reducers'
import { ConverterService } from './converter.service'
import { HubtypeApiService } from './hubtype-api/hubtype-api.service'

export interface ChatSummaryState {
  rated: boolean
  skipped: boolean
  summary?: HubtypeChatSummary
}

const CHAT_SUMMARIES_STATE_STORAGE_KEY = 'chatSummariesState'

@Injectable({
  providedIn: 'root',
})
export class AiAgentCopilotService {
  chatSummariesState: { [caseId: string]: ChatSummaryState } = {}

  constructor(
    private store: Store<fromRoot.State>,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('convertService') protected convertService: ConverterService
  ) {
    this.loadSummariesState()
  }

  addSummaryMessage(caze: HubtypeCase, messages: HubtypeMessage[]) {
    if (this.shouldAddSummaryMessage(caze, messages)) {
      const caseId = caze.id
      const summaryState = this.chatSummariesState[caseId]
      if (!summaryState || !summaryState.summary) {
        return null
      }
      Analytics.event(AnalyticsEvents.CASE_SUMMARY_RECOVERED, {
        caseId,
        summaryId: summaryState.summary.id,
      })
      this.dispatchMessage(caze, summaryState.summary)
    }
  }

  generateSummary(caze: HubtypeCase): Observable<HubtypeChatSummary> {
    const { id: caseId, status: caseStatus } = caze
    Analytics.event(AnalyticsEvents.CASE_SUMMARY_REQUESTED, {
      caseId,
      caseStatus,
    })
    return this.apiService
      .getV2({
        baseUrl: `${environment.baseURL}`,
        endpoint: `/ai/agent_copilot/summarize-case/${caze.id}`,
      })
      .pipe(
        map(summary =>
          this.convertService.jsonConvert.deserializeObject(
            summary,
            HubtypeChatSummary
          )
        ),
        tap((summary: HubtypeChatSummary) => {
          const {
            id: summaryId,
            numberOfMessagesUsed,
            lastMessageId,
            createdAt,
          } = summary
          this.addSummaryState(caseId, summary)
          this.dispatchMessage(caze, summary)
          Analytics.event(AnalyticsEvents.CASE_SUMMARY_COMPLETED, {
            caseId,
            caseStatus,
            summaryId,
            createdAt,
            numberOfMessagesUsed,
            lastMessageId,
          })
        }),
        catchError(error => {
          Analytics.event(AnalyticsEvents.CASE_SUMMARY_ERROR, {
            caseId,
            caseStatus,
          })
          return throwError(() => error)
        })
      )
  }

  getSummaryState(caseId: string): ChatSummaryState {
    return this.chatSummariesState[caseId]
  }

  rateSummary(caseId: string, summaryId: string, rating: SummaryRatingValue) {
    const summaryState = this.chatSummariesState[caseId]
    if (!summaryState) {
      return false
    }

    Analytics.event(AnalyticsEvents.CASE_SUMMARY_RATED, {
      caseId,
      summaryId,
      rating,
    })
    summaryState.rated = true
    this.saveSummariesState()
  }

  skipSummary(caze: HubtypeCase) {
    const { id: caseId, status: caseStatus } = caze
    const summaryState = this.addSummaryState(caseId)

    Analytics.event(AnalyticsEvents.CASE_SUMMARY_SKIPPED, {
      caseId,
      caseStatus,
    })

    summaryState.skipped = true
    this.saveSummariesState()
  }

  closeSummary(caseId: string, summaryId: string) {
    const summaryState = this.chatSummariesState[caseId]
    if (!summaryState) {
      return false
    }

    Analytics.event(AnalyticsEvents.CASE_SUMMARY_CLOSED, {
      caseId,
      summaryId,
    })

    summaryState.skipped = true
    summaryState.summary = null

    this.saveSummariesState()
  }

  private addSummaryState(
    caseId: string,
    summary?: HubtypeChatSummary
  ): ChatSummaryState {
    this.chatSummariesState[caseId] = {
      rated: false,
      skipped: false,
      summary,
    }
    this.saveSummariesState()

    return this.chatSummariesState[caseId]
  }

  private createSummaryMessage(caze: HubtypeCase, summary: HubtypeChatSummary) {
    const message = new HubtypeMessage()
    message.text = summary.text
    message.type = 'summary'
    message.action = 'summary'
    message.created_at = summary.createdAt
    message.case_id = caze.id
    message.chat_id = caze.chat.id
    message.queue_id = caze.queue_id
    message.translations = new HubtypeMessageTranslation()
    message.id = Math.random().toString()

    return message
  }

  private dispatchMessage(caze: HubtypeCase, summary: HubtypeChatSummary) {
    this.store.dispatch(
      new deskAction.NewMessageReceivedAction({
        case: caze,
        message: this.createSummaryMessage(caze, summary),
      })
    )
  }

  private loadSummariesState() {
    const state = sessionStorage.getItem(CHAT_SUMMARIES_STATE_STORAGE_KEY)
    if (state) {
      this.chatSummariesState = JSON.parse(state)
    }
  }

  private saveSummariesState() {
    sessionStorage.setItem(
      CHAT_SUMMARIES_STATE_STORAGE_KEY,
      JSON.stringify(this.chatSummariesState)
    )
  }

  private shouldAddSummaryMessage(
    caze: HubtypeCase,
    messages: HubtypeMessage[]
  ) {
    const summaryState = this.getSummaryState(caze?.id)
    const hasSummaryMessage = messages.find(m => m.is_summary)
    const summaryHasToBeOnThePage =
      summaryState?.summary &&
      messages.find(m => m.created_at < summaryState.summary.createdAt)

    if (
      hasSummaryMessage ||
      !summaryHasToBeOnThePage ||
      messages.length === 0
    ) {
      return false
    }

    return true
  }
}

export enum SummaryRatingValue {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}
